import React from 'react';
import { IconGift } from '@tabler/icons-react';
import moment from 'moment'; // Importamos Moment.js
import 'moment/locale/es'; // Opcional: para usar el idioma español
import './birthday.css';

function Birthday({ birthday }) {
    moment.locale('es');

    const today = moment();
    const currentYear = today.year();
    const birthdayDate = moment(birthday.birthday).year(currentYear);

    if (today.isAfter(birthdayDate, 'day')) {
        birthdayDate.add(1, 'year');
    }

    const isToday = today.isSame(birthdayDate, 'day');
    const daysRemaining = birthdayDate.diff(today, 'days');

    return (
        <div className="--card-birthday">
            <div className="--ico-birthday"><IconGift /></div>
            <div className="--txt-birthday">
                {isToday ? (
                    <p>Hoy es el cumple de <b>{birthday.name}</b></p>
                ) : daysRemaining === 0 ? (
                    <p>No tenemos cumpleaños cercanos</p>
                ) : (
                    <p>Celebraremos juntos dentro de {daysRemaining} día{daysRemaining !== 1 ? 's' : ''}</p>
                )}
            </div>
        </div>
    );
}

export default Birthday;