import React, { useContext, useEffect } from 'react'
import DataContext from '../../../context/DataContext';

import Food from '../../Cards/Food/Food'

import './styles/book.css'

function Bookings() {

    const { handleGetReserved, productsCoffeeReserved } = useContext(DataContext);

    useEffect(() => {
        if (productsCoffeeReserved.length === 0) {
            handleGetReserved();
        }
    }, [handleGetReserved, productsCoffeeReserved.length])

    return (

        <>
        
            <h3>Reservaciones</h3>

            <div className='--line'></div>

            {productsCoffeeReserved.length === 0 ? (
                <h4>No hay reservaciones aún</h4>
            ) : (
                <>
                
                    {productsCoffeeReserved.map((lunch, index) => (
                        <Food key={index} type={'2'} product={lunch} />
                    ))}

                </>
            )}

        </>

    )
    
}

export default Bookings