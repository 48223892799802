import React from 'react'
import moment from 'moment';

import './pension.css'

function Pension({ pension }) {

    const pensionDate = moment(`${pension.mes} ${pension.dia}`, 'MMM DD');
    const isPast = moment().isAfter(pensionDate, 'day');
    const statusPay = isPast ? 'Pagado' : 'Pendiente';

    return (

        <div className={`--card-pension`}>
            <div className='--col --col-A'>
                <div className='--date'>
                    <span className='--span-day-mont'>{pension.mes}</span>
                    <span className='--span-day-numb'>{pension.dia}</span>
                </div>
                <div className='--text'>
                    <h4>{pension.name}</h4>
                    <span>{statusPay}</span>
                </div>
            </div>
            <div className='--col --col-B'>
                <span className='--price-totally'>s/. 250.00</span>
            </div>
        </div>

    )

}

export default Pension