import React, { useContext, useState } from 'react'
import { IconChevronLeft } from '@tabler/icons-react'
import moment from 'moment';
import UIContext from '../../../context/UIContext'
import { API } from '../../../api/api';
import DataContext from '../../../context/DataContext';

import './styles/form.css'

function CreateView() {

    const { handleChangeView } = useContext(UIContext);
    const { handleGetProducts } = useContext(DataContext);

    const [ dataCreated, setDataCreated ] = useState({
        nameP: '',
        textP: '',
        categoryP: '',
        priceP: '',
        amountP: ''
    });

    const [ isLoadingCreated, setIsLoadingCreated ] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setDataCreated(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const handleCreateProduct = async () => {

        setIsLoadingCreated(true);

        const today = moment().format('YYYY-MM-DD');

        const formData = new FormData();
        formData.append('action', 'create')
        formData.append('name', dataCreated.nameP)
        formData.append('text', dataCreated.textP)
        formData.append('category', dataCreated.categoryP)
        formData.append('price', dataCreated.priceP)
        formData.append('amount', dataCreated.amountP)
        formData.append('date', today);

        try {
            
            await fetch(`${API.api}/coffee/`, {
                method: 'POST',
                body: formData
            })
            .then((response) => response.json())
            .then((data) => {
                if (data.ok) {
                    handleChangeView('home');
                    handleGetProducts();
                } else {
                    console.log(data);
                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setIsLoadingCreated(false);
            })

        } catch (error) {
            
            console.log(error);

        }

    }

    return (
    
        <>

            <div className='--head-form'>
                <button className='--btn-back' onClick={() => handleChangeView('home')}><IconChevronLeft/></button>
            </div>
        
            <div className='--form'>

                <div className='--form-group'>
                    <label className='--label-form-product' htmlFor='nameP'>Nombre del plato o producto</label>
                    <input className='--entry-form-product' type='text' name='nameP' id='nameP' placeholder='Nombre del plato o producto' aria-placeholder='Nombre del plato o producto' onChange={handleChange}/>
                </div>

                <div className='--form-group'>
                    <label className='--label-form-product' htmlFor='textP'>Descripción del plato o producto</label>
                    <input className='--entry-form-product' type='text' name='textP' id='textP' placeholder='Descripción del plato o producto' aria-placeholder='Descripción del plato o producto' onChange={handleChange}/>
                </div>

                <div className='--form-group'>
                    <label className='--label-form-product' htmlFor='categoryP'>Categoria</label>
                    <select className='--select-form-product' name='categoryP' id='categoryP' defaultValue={''} onChange={handleChange}>
                        <option value={''} hidden>Selecciona la categoria</option>
                        <option value={'0'}>Producto</option>
                        <option value={'1'}>Almuerzo</option>
                    </select>
                </div>

                <div className='--form-group'>
                    <label className='--label-form-product' htmlFor='priceP'>Precio del plato o producto</label>
                    <input className='--entry-form-product' type='text' name='priceP' id='priceP' placeholder='Precio del plato o producto' aria-placeholder='Precio del plato o producto' onChange={handleChange}/>
                </div>

                <div className='--form-group'>
                    <label className='--label-form-product' htmlFor='amountP'>Cantidad del plato o producto</label>
                    <input className='--entry-form-product' type='text' name='amountP' id='amountP' placeholder='Cantidad del plato o producto' aria-placeholder='Cantidad del plato o producto' onChange={handleChange}/>
                </div>

                <div className='--form-group'>
                    <button className='--btn' onClick={handleCreateProduct}>{isLoadingCreated ? 'Creando...' : 'Crear'}</button>
                </div>

            </div>
        
        </>
    
    )

}

export default CreateView