import React, { useContext } from 'react'
import { IconHome, IconBook, IconCalendar, IconWallet, IconUserCircle } from '@tabler/icons-react'

import UIContext from '../../context/UIContext'

import '../styles/footer.css'

function Footer() {

    const { isView, handleChangeView } = useContext(UIContext);

    return (

        <footer className={`--footer`}>

            <div className='--content-footer'>

                <button className={`--btn --btn-nav ${isView === 'home' ? '--btn-nav--active' : ''}`} onClick={() => handleChangeView('home')}><IconHome className='--ico-tabler'/> <span className='--txt'>Inicio</span></button>
                <button className={`--btn --btn-nav ${isView === 'courses' ? '--btn-nav--active' : ''}`}  onClick={() => handleChangeView('courses')}><IconBook className='--ico-tabler'/><span className='--txt'>Cursos</span></button>
                <button className={`--btn --btn-nav ${isView === 'schedule' ? '--btn-nav--active' : ''}`}  onClick={() => handleChangeView('schedule')}><IconCalendar className='--ico-tabler'/><span className='--txt'>Horario</span></button>
                <button className={`--btn --btn-nav ${isView === 'pensions' ? '--btn-nav--active' : ''}`}  onClick={() => handleChangeView('pensions')}><IconWallet className='--ico-tabler'/><span className='--txt'>Pensión</span></button>
                <button className={`--btn --btn-nav ${isView === 'profile' ? '--btn-nav--active' : ''}`}  onClick={() => handleChangeView('profile')}><IconUserCircle className='--ico-tabler'/><span className='--txt'>Perfil</span></button>

            </div>

        </footer>

    )

}

export default Footer