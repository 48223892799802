import React, { useEffect } from 'react'

function CreateMeetView() {

    useEffect(() => {
        // Solicitar permiso para mostrar notificaciones al montar el componente
        if (Notification.permission === 'default') {
            Notification.requestPermission().then(permission => {
                if (permission === 'granted') {
                    console.log('Permiso de notificación concedido.');
                } else {
                    console.log('Permiso de notificación no concedido.');
                }
            });
        }
    }, []);

    const requestPermission = () => {
        Notification.requestPermission().then(permission => {
            if (permission === 'granted') {
                sendNotification();
            } else {
                alert('Permiso de notificación no concedido. Por favor, activa las notificaciones en la configuración del navegador.');
            }
        });
    };

    const sendNotification = () => {
        if (Notification.permission === 'granted') {
            navigator.serviceWorker.ready.then(registration => {
                registration.showNotification('Notificación Local', {
                    body: 'Esta es una notificación local desde el frontend.',
                    icon: '/icon.png',
                    badge: '/badge.png'
                });
            });
        } else if (Notification.permission === 'default') {
            // Solicitar permiso si aún no se ha decidido
            requestPermission();
        } else {
            // Mostrar un mensaje si el permiso fue denegado
            alert('Permiso de notificación no concedido. Por favor, activa las notificaciones en la configuración del navegador.');
        }
    };

    const handleVibrate = () => {
        if (navigator.vibrate) {
            navigator.vibrate(200);
        } else {
            alert("La API de Vibración no es soportada por este dispositivo.");
        }
    };

    return (

        <>
        
            <h3>Crear una reunión</h3>
            <button onClick={sendNotification}>Enviar notification</button>
            <button onClick={handleVibrate}>Enviar Vibración</button>

        </>

    )

}

export default CreateMeetView