import React from 'react'

import Header from '../layout/Student/Header'
import Footer from '../layout/Student/Footer'
import Main from '../layout/Student/Main'

function StudentView() {

    return (

        <>
        
            <Header/>

            <Main/>

            <Footer/>

        </>

    )
    
}

export default StudentView