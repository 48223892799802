import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom';
import { IconEye, IconEyeOff, IconKey, IconUser } from '@tabler/icons-react'

import AuthenticatedContext from '../context/AuthenticatedContext';

import Loader from '../components/Loaders/Loader'

import logo from '../static/img/294508661_427891509387613_7578898815592663054_n 3.svg'
import './styles/login.css'

function LoginScreen() {

    const { isLoadingAuth, handleLogin } = useContext(AuthenticatedContext);

    const [ userCredencials, setUserCredencials ] = useState({
        uuid: '',
        uupw: ''
    });
    const [ isViewPassword, setIsViewPassword ] = useState(false);

    const handleChangeEntry = (e) => {
        const { name, value } = e.target;
        setUserCredencials(prevUserData => ({
            ...prevUserData,
            [name]: value
        }));
    };

    const handleLoginUser = () => handleLogin(userCredencials.uuid, userCredencials.uupw);

    return (

        <div className='--app-l0g1n'>

            <div className='--c9ol-l0go --grid --grid-centered'>

                <div className='--figure'><img src={logo} alt='Logo de Colegios y Academias Sigma' /></div>

            </div>

            <div className='--c9ol-f0rm'>

                <div className='--card'>

                    <h1 className='--txt-primary'>Bienvenido</h1>
                    <p className='--txt-secondary'>Ingresa con tus <b>credenciales</b> para acceder a más herramientas.</p>

                    <div className='--form'>
                        <div className='--form-group'>
                            <div className='--form-control --relative'>
                                <span className='--grid --grid-centered --sw-50 --sh-50 --ico-form --absolute --ico-form-left'><IconUser className='--ico-tabler'/></span>
                                <input type='text' className='--entry --entry-block --sh-50 --sbr-10' name='uuid' id='uuid' value={userCredencials.uuid} placeholder='Ingresar ID' autoComplete='off' onChange={handleChangeEntry}/>
                            </div>
                        </div>
                        <div className='--form-group'>
                            <div className='--form-control --relative'>
                                <span className='--grid --grid-centered --sw-50 --sh-50 --ico-form --absolute --ico-form-left'><IconKey className='--ico-tabler'/></span>
                                <input type={`${isViewPassword ? 'text' : 'password'}`} className='--entry --entry-block --sh-50 --sbr-10' name='uupw' id='uupw' value={userCredencials.uupw} placeholder='Ingresar ID' autoComplete='off' onChange={handleChangeEntry} />
                                <span className='--grid --grid-centered --sw-50 --sh-50 --ico-form --absolute --ico-form-right --pointer' onClick={() => setIsViewPassword(!isViewPassword)}>{isViewPassword ? <IconEyeOff className='--ico-tabler' /> : <IconEye className='--ico-tabler'/>}</span>
                            </div>
                            <Link className='--a-tool'>¿Olvidaste tu contraseña?</Link>
                        </div>
                        <div className='--form-group'>
                            <div className='--form-control'>
                                <button className='--btn --btn-block --btn-primary --sh-50 --sbr-10' onClick={handleLoginUser}>{ isLoadingAuth ? <Loader/> : 'Ingresar' }</button>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </div>

    )

}

export default LoginScreen