import React, { useContext } from 'react'
import moment from 'moment';
import { IconCake, IconCertificate, IconDisabled, IconGenderFemale, IconGenderMale, IconId, IconMapPin, IconPhone, IconQrcode, IconUsersGroup } from '@tabler/icons-react';

import UIContext from '../../../context/UIContext';
import AuthenticatedContext from "../../../context/AuthenticatedContext";

import portadaDefault from '../../../static/img/banner_portada.png'
import avatarDefault from '../../../static/img/FotoDefault.png'

import './styles/profile.css'

function ProfileView() {

    const { handleChangeView } = useContext(UIContext);
    const { isUser } = useContext(AuthenticatedContext);

    const formattedBirthday = moment(isUser.birthday).format('DD [de] MMMM [del] YYYY');
    const formattedTuition = moment(isUser.tuition).format('DD [de] MMMM [del] YYYY');

    return (

        <>
        
            <div className='--portada'>
                <img src={portadaDefault} alt={`Foto de portada de ${isUser.name}`} />
                <div className='--avatar'>
                    <img src={avatarDefault} alt={`Foto de perfil de ${isUser.name}`} />
                </div>
            </div>

            <div className='--info-name'>
                <h2>{isUser.name} {isUser.lastname} {isUser.secondname}</h2>
                <span>{isUser.level} | {isUser.grade}</span>
            </div>

            <div className='--options'>
                <button className='--btn-opt' onClick={() => handleChangeView('code')}><IconQrcode/></button>
            </div>

            <div className='--data'>

                <div className='--tit-data'>Información Personal</div>

                <div className='--wrap-dt'>
                    <span className='--ico'><IconId/></span>
                    <span className='--txt'>{isUser.dni}</span>
                </div>
                <div className='--wrap-dt'>
                    <span className='--ico'><IconCake/></span>
                    <span className='--txt'>{formattedBirthday}</span>
                </div>
                <div className='--wrap-dt'>
                    <span className='--ico'><IconPhone/></span>
                    <span className='--txt'>{isUser.phone === '' ? isUser.attorney_phone : isUser.phone}</span>
                </div>
                <div className='--wrap-dt'>
                    <span className='--ico'>{isUser.sex === 'M' ? <IconGenderMale/> : <IconGenderFemale/>}</span>
                    <span className='--txt'>{isUser.sex === 'M' ? 'Masculino' : 'Femenino'}</span>
                </div>
                <div className='--wrap-dt'>
                    <span className='--ico'><IconDisabled/></span>
                    <span className='--txt'>{isUser.disease}</span>
                </div>
                <div className='--wrap-dt'>
                    <span className='--ico'><IconCertificate/></span>
                    <span className='--txt'>{formattedTuition}</span>
                </div>
                <div className='--wrap-dt'>
                    <span className='--ico'><IconMapPin/></span>
                    <span className='--txt'>{isUser.direction}</span>
                </div>

                <div className='--tit-data'>Información Emergencia</div>

                <div className='--wrap-dt'>
                    <span className='--ico'><IconUsersGroup/></span>
                    <span className='--txt'>{isUser.attorney_name} {isUser.attorney_lastname} {isUser.attorney_secondname}</span>
                </div>
                <div className='--wrap-dt'>
                    <span className='--ico'><IconId/></span>
                    <span className='--txt'>{isUser.attorney_dni}</span>
                </div>
                <div className='--wrap-dt'>
                    <span className='--ico'><IconPhone/></span>
                    <span className='--txt'>{isUser.attorney_phone}</span>
                </div>

            </div>

            <button className='--edit-profile'>Editar perfil</button>

        </>

    )

}

export default ProfileView