import React, { useContext } from 'react'

import AuthenticatedContext from '../context/AuthenticatedContext'

import AdminView from '../views/AdminView';
import TeacherView from '../views/TeacherView';
import StudentView from '../views/StudentView';
import CaffetinView from '../views/CaffetinView';
import MenuBar from '../components/MenuBar/MenuBar';

import './styles/home.css'

function HomeScreen() {

    const { isUser } = useContext(AuthenticatedContext);

    return (

        <div className='--app-h0m3'>

            {isUser.rol === '1' && ( <AdminView/> )}
            {isUser.rol === '2' && ( <TeacherView/> )}
            {isUser.rol === '3' && ( <StudentView/> )}
            {isUser.rol === '5' && ( <CaffetinView/>  )}

            <MenuBar/>

        </div>

    )

}

export default HomeScreen