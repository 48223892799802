import React from 'react'

import Mettings from '../../Sections/Mettings'
import Birthdays from '../../Sections/Birthdays'
import Psychology from '../../Sections/Psychology'
import Foods from '../../Sections/Foods'
import Assitance from '../../Sections/Assitance'

function HomeView() {

    return (
    
        <>
        
            <Mettings/>

            <Birthdays/>

            <Assitance/>

            <Psychology/>

            <Foods/>

        </>
    
    )

}

export default HomeView