import React, { createContext, useState, useEffect, useContext } from "react";
import Cookies from "js-cookie";
import moment from "moment";
import { API } from "../api/api";
import { jwtDecode } from "jwt-decode";

import AuthenticatedContext from "./AuthenticatedContext";

const DataContext = createContext();

export const DataProvider = ({ children }) => {

    const { isUser } = useContext(AuthenticatedContext);

    const [ meetings, setMeetings ] = useState([]);
    const [ birthdays, setBirthdays ] = useState([]);
    const [ courses, setCourses ] = useState([]);
    const [ schedules, setSchedules ] = useState([]);

    const [ productsCoffee, setProductsCoffee ] = useState([]);
    const [ productsCoffeeReserved, setProductsCoffeeReserved ] = useState([]);

    const [ reserved, setReserved ] = useState({
        productId: '',
        productAmount: 1,
    });

    const handleGetMeetings = async () => {

        const formData = new FormData();
        formData.append('r_user', isUser.rol)
        const currentDateTime = moment().format('YYYY-MM-DD HH:mm:ss');
        formData.append('currentDateTime', currentDateTime);

        try {
            
            const response = await fetch(`${API.api}/mettings/`, {
                method: 'POST',
                body: formData
            })
            const data = await response.json();
            if (data.ok) {
                setMeetings(data.content);
            }

        } catch (error) {
            console.log(error);
        }

    }

    const handleGetBirthdays = async () => {

        const monthActually = moment().month() + 1;

        try {
            
            const response = await fetch(`${API.api}/birthdays/?moment_month=${monthActually}`);
            const data = await response.json();
            if (data.ok) {
                setBirthdays(data.data);
            } else {
                console.log(data.error);
            }

        } catch (error) {
            console.log(error.message);
        }

    }

    const handleGetCourses = async (grade) => {

        try {

            const cookieCourses = localStorage.getItem('c_courses');

            if (cookieCourses) {

                const decodedCourses = jwtDecode(cookieCourses);
                setCourses(decodedCourses);

            } else {

                const response = await fetch(`${API.api}/courses/?course_code=${grade}`);
                const data = await response.json();
                if (data.ok) {
                    localStorage.setItem('c_courses', data.courses)
                    Cookies.set('c_courses', data.courses, { expires: 365, secure: true })
                    const decodedCourses = jwtDecode(data.courses);
                    setCourses(decodedCourses);
                } else {
                    console.log(data.message);
                }

            }

        } catch (error) {
            console.log(error.message);
        }

    }

    const handleGetSchedules = async (grade) => {

        try {
            
            const cookieSchedule = localStorage.getItem('c_schedule');

            if (cookieSchedule) {

                const decodedSchedule = jwtDecode(cookieSchedule);
                setSchedules(decodedSchedule);

            } else {

                const response = await fetch(`${API.api}/schedule/?schedule_code=${grade}`)
                const data = await response.json();
                if (data.ok) {
                    Cookies.set('c_schedule', data.schedules, { expires: 365, secure: true });
                    localStorage.setItem('c_schedule', data.schedules);
                    const decodedSchedule = jwtDecode(data.schedules)
                    setSchedules(decodedSchedule)
                } else {
                    console.log(data.message);
                }

            }

        } catch (error) {
            console.log(error.message);
        }
    }

    const handleGetProducts = async () => {

        const today = moment().format('YYYY-MM-DD');

        const formData = new FormData();
        formData.append('action', 'get')
        formData.append('today', today)
        formData.append('clientId', isUser.id)

        try {
            
            const response = await fetch(`${API.api}/coffee/`,{
                method: 'POST',
                body: formData
            });
            const data = await response.json();
            if (data.ok && data.total > 0) {
                setProductsCoffee(data.content);
            } else {
                console.log(data.message);
            }

        } catch (error) {
            console.log(error.message);
        }

    }

    const handleGetReserved = async () => {

        const today = moment().format('YYYY-MM-DD')
        const formData = new FormData();
        formData.append('action', 'getBookings')
        formData.append('today', today)
        formData.append('role', isUser.rol)

        try {
            
            await fetch(`${API.api}/coffee/`, {
                method: 'POST',
                body: formData
            })
            .then((response) => response.json())
            .then((data) => {
                if (data.ok) {
                    setProductsCoffeeReserved(data.content);
                }
            })
            .catch((error) => {
                console.log(error);
            })

        } catch (error) {
            console.log(error);
        }

    }

    useEffect(() => {
        if (isUser.rol !== 5) {
            handleGetBirthdays();
        }
    }, [isUser]);

    const contextValue = {
        meetings,
        handleGetMeetings,
        birthdays,
        courses, handleGetCourses,
        schedules, handleGetSchedules,
        reserved, setReserved,

        handleGetProducts, productsCoffee,
        handleGetReserved,
        productsCoffeeReserved
    }

    return (
        <DataContext.Provider value={contextValue}>{children}</DataContext.Provider>
    )

}

export default DataContext;