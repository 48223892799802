import React, { useContext } from 'react'
import { IconHome, IconListCheck, IconUserCircle } from '@tabler/icons-react'

import UIContext from '../../context/UIContext'

import '../styles/footer.css'

function Footer() {

    const { isView, handleChangeView } = useContext(UIContext);

    return (

        <footer className={`--footer`}>

            <div className='--content-footer'>

                <button className={`--btn --btn-nav ${isView === 'home' ? '--btn-nav--active' : ''}`} onClick={() => handleChangeView('home')}><IconHome className='--ico-tabler'/> <span className='--txt'>Inicio</span></button>
                <button className={`--btn --btn-nav ${isView === 'bookings' ? '--btn-nav--active' : ''}`}  onClick={() => handleChangeView('bookings')}><IconListCheck className='--ico-tabler'/><span className='--txt'>Reservas</span></button>
                <button className={`--btn --btn-nav ${isView === 'profile' ? '--btn-nav--active' : ''}`}  onClick={() => handleChangeView('profile')}><IconUserCircle className='--ico-tabler'/><span className='--txt'>Perfil</span></button>

            </div>

        </footer>

    )

}

export default Footer