import React from "react";
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { UIProvider } from "./context/UIContext";
import { AuthenticatedProvider } from "./context/AuthenticatedContext";
import { DataProvider } from "./context/DataContext";

import { registerServiceWorker } from "./sw.js";

import App from "./App";

import './static/css/global.css'
import './static/css/custom.css'

const router = createBrowserRouter([
    {
        path: '/',
        element: <App/>,
    }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(

    <UIProvider>

        <AuthenticatedProvider>

            <DataProvider>
    
                <RouterProvider router={router} />
            
            </DataProvider>

        </AuthenticatedProvider>
        
    </UIProvider>

);

registerServiceWorker();