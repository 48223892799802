import React from 'react'
import Header from '../layout/Teacher/Header'
import Footer from '../layout/Teacher/Footer'
import Main from '../layout/Teacher/Main'

function TeacherView() {

    return (

        <>
        
            <Header/>

            <Main/>

            <Footer/>

        </>

    )
    
}

export default TeacherView