import React, { createContext, useEffect, useState } from "react";
import moment from "moment";

const UIContext = createContext();

export const UIProvider = ({ children }) => {

    const [ isView, setIsView ] = useState('home');
    const [ timeOfDay, setTimeOfDay ] = useState(false);
    const [ isViewMenu, setIsViewMenu ] = useState(false);

    const [ isSnackBar, setIsSnackBar ] = useState({
        type: '',
        message: ''
    });

    const handleChangeView = (view) => setIsView(view);
    const handleSnackBar = (snackBar) => setIsSnackBar({ type: snackBar.type, message: snackBar.message })
    const handleViewMenu = () => setIsViewMenu(!isViewMenu);

    const handleTimeOfDay = () => {
        const currentTime = moment();

        // Definir los rangos de tiempo según tu criterio
        const morningStart = moment('06:00:00', 'HH:mm:ss');
        const afternoonStart = moment('12:00:00', 'HH:mm:ss');
        const eveningStart = moment('18:00:00', 'HH:mm:ss');
    
        if (currentTime.isBetween(morningStart, afternoonStart)) {
          setTimeOfDay('Buen día');
        } else if (currentTime.isBetween(afternoonStart, eveningStart)) {
          setTimeOfDay('Buena tarde');
        } else {
          setTimeOfDay('Buena noche');
        }
    }

    useEffect(() => {
        handleTimeOfDay();
    }, []);

    const contextValue = {
        isView, handleChangeView,
        isSnackBar, handleSnackBar,
        timeOfDay,
        isViewMenu,
        handleViewMenu
    }

    return (
        <UIContext.Provider value={contextValue}>{children}</UIContext.Provider>
    )

}

export default UIContext;