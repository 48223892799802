import React, { useContext, useEffect } from 'react'

import AuthenticatedContext from "../../context/AuthenticatedContext";
import DataContext from '../../context/DataContext';

import Meet from '../Cards/Mettings/Meet'
import CreateMeet from '../Cards/Mettings/CreateMeet'
import MeetEmpty from '../Cards/Mettings/MeetEmpty';

import './styles.css'

function Mettings() {

    const { isUser } = useContext(AuthenticatedContext);
    const { handleGetMeetings, meetings } = useContext(DataContext);

    useEffect(() => {
        handleGetMeetings();
    }, [handleGetMeetings]);

    return (

        <div>

            <h3 className='--title-cards'>Reuniones Próximas</h3>

            <div className='--lst-cards'>
                {isUser.rol !== "3" && ( <CreateMeet/> )}
                {meetings.length > 0 ? (
                    <>
                        {meetings.map((meet, i) => (
                            <Meet key={i} meetData={meet} />
                        ))}
                    </>
                ) : (
                    <MeetEmpty/>
                )}
            </div>

        </div>

    )

}

export default Mettings