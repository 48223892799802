import React, { useContext } from 'react'
import UIContext from '../../context/UIContext'
import HomeView from '../../components/views/Admin/HomeView';

import '../styles/main.css'
import ProfileView from '../../components/views/Admin/ProfileView';
import CreateMeetView from '../../components/views/Admin/CreateMeetView';

function Main() {

    const { isView } = useContext(UIContext);

    return (

        <main className={`--main`}>

            {isView === 'home' && ( <HomeView/> )}
            
            {isView === 'createMeet' && ( <CreateMeetView/> )}

            {isView === 'profile' && ( <ProfileView/> )}

        </main>

    )

}

export default Main