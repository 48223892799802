import React from 'react'

import Mettings from '../../Sections/Mettings'
import Birthdays from '../../Sections/Birthdays'
import Psychology from '../../Sections/Psychology'
import Foods from '../../Sections/Foods'

function HomeView() {

    return (

        <>
        
            <Mettings/>

            <Birthdays/>

            <Psychology/>

            <Foods/>

        </>

    )

}

export default HomeView