import React from 'react'
import moment from 'moment'
import { IconCalendar, IconClock } from '@tabler/icons-react'

import defaultFoto from '../../../static/img/FotoDefault.svg'

import './meet.css'

function Meet({ meetData }) {

    //datetime: "2024-04-24 02:00:00"
    const currentDate = moment();
    const eventDate = moment(meetData.datetime, "YYYY-MM-DD HH:mm:ss");
    const daysDifference = eventDate.diff(currentDate, 'days');

    // Formatear la hora del evento
    const formattedEventTime = eventDate.format('h:mm A');

    return (

        <div className={`--card-meet`}>

            <div className='--card-meet-head'>

                <div className='--figure'>
                    <img src={defaultFoto} alt='Foto de perfil' />
                </div>
                <h4>{meetData.creator}</h4>

            </div>

            <div className='--card-meet-body'>
                <p>{meetData.body}</p>
            </div>

            <div className='--card-meet-foot'>
                <div className='--ico-date'><IconCalendar/> en {daysDifference} días</div>
                <div className='--ico-date'><IconClock/> {formattedEventTime}</div>
            </div>

        </div>

    )

}

export default Meet