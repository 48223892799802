import React, { useContext } from 'react'
import moment from 'moment'
import 'moment/locale/es';

import DataContext from "../../context/DataContext";

import Birthday from '../Cards/Birthdays/Birthday'

function Birthdays() {

    moment.locale('es');
    const nameMonth = moment().format('MMMM');

    const { birthdays } = useContext(DataContext);

    console.log(birthdays);
    
    return (

        <div>

            <h3 className='--title-cards'>Cumpleaños de <span>{nameMonth}</span></h3>

            <div className='--lst-cards'>
                {birthdays.map((birthday) => (
                    <Birthday key={birthday.id_usuario} birthday={birthday} />
                ))}
            </div>

        </div>

    )

}

export default Birthdays