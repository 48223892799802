import React, { useContext } from 'react'
import moment from 'moment';
import { IconCake, IconSchool, IconDisabled, IconGenderFemale, IconGenderMale, IconId, IconMapPin, IconPhone } from '@tabler/icons-react';

import AuthenticatedContext from "../../../context/AuthenticatedContext";

import portadaDefault from '../../../static/img/banner_portada.png'
import avatarDefault from '../../../static/img/FotoDefault.png'

import './styles/profile.css'

function ProfileView() {

    const { isUser } = useContext(AuthenticatedContext);

    const formattedBirthday = moment(isUser.birthday).format('DD [de] MMMM [del] YYYY');

    return (

        <>
        
            <div className='--portada'>
                <img src={portadaDefault} alt={`Foto de portada de ${isUser.name}`} />
                <div className='--avatar'>
                    <img src={avatarDefault} alt={`Foto de perfil de ${isUser.name}`} />
                </div>
            </div>

            <div className='--info-name'>
                <h2>{isUser.name} {isUser.lastname} {isUser.secondname}</h2>
            </div>

            <div className='--data'>

                <div className='--tit-data'>Información Personal</div>

                <div className='--wrap-dt'>
                    <span className='--ico'><IconId/></span>
                    <span className='--txt'>{isUser.dni}</span>
                </div>
                <div className='--wrap-dt'>
                    <span className='--ico'><IconCake/></span>
                    <span className='--txt'>{formattedBirthday}</span>
                </div>
                <div className='--wrap-dt'>
                    <span className='--ico'><IconPhone/></span>
                    <span className='--txt'>{isUser.phone}</span>
                </div>
                <div className='--wrap-dt'>
                    <span className='--ico'>{isUser.sex === 'M' ? <IconGenderMale/> : <IconGenderFemale/>}</span>
                    <span className='--txt'>{isUser.sex === 'M' ? 'Masculino' : 'Femenino'}</span>
                </div>
                <div className='--wrap-dt'>
                    <span className='--ico'><IconSchool/></span>
                    <span className='--txt'>{isUser.position}</span>
                </div>
                <div className='--wrap-dt'>
                    <span className='--ico'><IconDisabled/></span>
                    <span className='--txt'>{isUser.disease}</span>
                </div>
                <div className='--wrap-dt'>
                    <span className='--ico'><IconMapPin/></span>
                    <span className='--txt'>{isUser.direction}</span>
                </div>

            </div>

            <button className='--edit-profile'>Editar perfil</button>

        </>

    )

}

export default ProfileView