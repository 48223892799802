import React from 'react'
import moment from 'moment';
import { IconCircleCheck } from "@tabler/icons-react";

import './asistant.css'

function Asistant({ day }) {

    const dayOfMonth = day.date().toString().padStart(2, '0');
    const weekDay = day.format('ddd');

    const currentDay = moment();
    const weekDayAbbreviation = currentDay.format('ddd');
    const dayOfMonthToday = currentDay.format('DD').toString().padStart(2, '0');

    return (

        <div className={`--card-assitant ${dayOfMonthToday === dayOfMonth && weekDay === weekDayAbbreviation ? '--card-assitant--active' : ''}`}>


            <div className='--ico-'>
                <span className='--i'><IconCircleCheck/></span>
            </div>

            <div className='--tt-'>
                <p className='--day-num'>{dayOfMonth}</p>
                <p className='--day-txt'>{weekDay}</p>
            </div>

        </div>

    )

}

export default Asistant