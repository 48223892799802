import React from 'react'
import Header from '../layout/Caffetin/Header'
import Footer from '../layout/Caffetin/Footer'
import Main from '../layout/Caffetin/Main'

function CaffetinView() {

    return (

        <>
        
            <Header/>

            <Main/>

            <Footer/>

        </>

    )

}

export default CaffetinView