import React from 'react'

import PsychologyCard from '../Cards/Psychology/PsychologyCard'

function Psychology() {

    const mentalHealthTips = [
        {
            title: "Practica la gratitud",
            content: "Tómate un momento cada día para reflexionar sobre las cosas por las que estás agradecido. Esto puede ayudarte a mantener una perspectiva positiva y a reducir el estrés."
        },
        {
            title: "Haz ejercicio regularmente",
            content: "El ejercicio no solo es bueno para tu cuerpo, también tiene numerosos beneficios para tu salud mental. Intenta hacer actividades físicas que disfrutes, como caminar, correr, yoga o bailar."
        },
        {
            title: "Mantén contacto social",
            content: "La conexión con otras personas es fundamental para tu bienestar emocional. Dedica tiempo a estar con amigos, familiares o seres queridos, ya sea en persona o a través de llamadas telefónicas o videoconferencias."
        },
        {
            title: "Practica la autocompasión",
            content: "Sé amable contigo mismo. En lugar de ser duro contigo mismo por tus errores o imperfecciones, trata de tratarte con la misma comprensión y amabilidad que lo harías con un amigo."
        },
        {
            title: "Toma descansos",
            content: "A lo largo del día, tómate pequeños descansos para relajarte y recargar energías. Esto puede ayudarte a mantener la concentración y a reducir la sensación de agobio."
        }
    ];

    return (

        <div>

            <h3 className='--title-cards'>Salud Mental</h3>

            <div className='--lst-cards'>

                {mentalHealthTips.map((mental, i) => (
                    <PsychologyCard key={i} mentalBox={mental} />
                ))}
                
            </div>

        </div>

    )

}

export default Psychology