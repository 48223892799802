import React, { useContext, useEffect } from 'react'
import { IconPlus } from '@tabler/icons-react';

import UIContext from '../../../context/UIContext';
import DataContext from '../../../context/DataContext';

import Product from '../../Cards/Products/Product';

import './styles/home.css'

function HomeView() {

    const { handleChangeView } = useContext(UIContext);
    const { handleGetProducts, productsCoffee } = useContext(DataContext);

    useEffect(() => {
        if (productsCoffee.length === 0) {
            handleGetProducts();
        }
    }, [handleGetProducts, productsCoffee]);

    return (

        <>

            <button className='--btn-create' onClick={() => handleChangeView('createProduct')}><IconPlus/></button>

            <>
            
                {productsCoffee.map((product) => (
                    <Product key={product.id_product} product={product}/>
                ))}

            </>

        </>

    )

}

export default HomeView