import React from 'react'
import moment from 'moment'

import Asistant from '../Cards/Asistence/Asistant'

function Assitance() {

    const firstDayOfWeek = moment().startOf('week');
    const weekDays = [...Array(5)].map((_, index) => firstDayOfWeek.clone().add(index, 'days'));
    
    return (
    
        <div>

            <h3 className='--title-cards'>Asistencia de la semana</h3>

            <div className='--lst-cards'>

                {weekDays.map((day, index) => (
                    <Asistant key={index} day={day} />
                ))}

            </div>

        </div>
    
    )

}

export default Assitance