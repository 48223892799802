import React, { useContext } from 'react'

import UIContext from '../../context/UIContext';

import HomeView from '../../components/views/Teacher/HomeView';
import ProfileView from '../../components/views/Teacher/ProfileView';

import '../styles/main.css'

function Main() {

    const { isView } = useContext(UIContext);

    return (

        <main className='--main'>

            {isView === 'home' && ( <HomeView/> )}
            {isView === 'profile' && ( <ProfileView/>  )}

        </main>

    )

}

export default Main