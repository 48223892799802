import React, { useContext, useEffect } from 'react'
import AuthenticatedContext from './context/AuthenticatedContext'
import LoginScreen from './screens/LoginScreen';
import UIContext from './context/UIContext';
import SnackBar from './components/SnackBar/SnackBar';
import HomeScreen from './screens/HomeScreen';

function App() {

    const { isSnackBar } = useContext(UIContext);
    const { isAuthenticated } = useContext(AuthenticatedContext);

    useEffect(() => {

        const askNotificationPermission = async () => {
            try {
                const permission = await Notification.requestPermission();
                if (permission === 'granted') {
                    new Notification('Permiso de notificación concedido', {
                        body: '¡Ahora puedes recibir notificaciones de cualquier evento!'
                    });
                } else {
                    alert('El usuario denegó el permiso de notificación.');
                }
            } catch (error) {
                console.error('Error al solicitar permiso de notificación:', error);
                alert(`Error al solicitar permiso de notificación: ${error.message}`);
            }
        }
    
        askNotificationPermission();

    }, []);

    return (

        <>
        
            {isAuthenticated ? <HomeScreen/> : <LoginScreen/> }

            {isSnackBar.message !== '' && ( <SnackBar snack={isSnackBar} /> )}

        </>

    )

}

export default App