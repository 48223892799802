import React, { useContext } from 'react'
import { IconPlus } from '@tabler/icons-react'

import UIContext from '../../../context/UIContext'

function CreateMeet() {

    const { handleChangeView } = useContext(UIContext);

    return (

        <div className='--card-create-meet' onClick={() => handleChangeView('createMeet')}>
            <div className='--content-create'>
                <span className='--span-ico'><IconPlus/></span>
                <span className='--span-txt'>Crear</span>
            </div>
        </div>

    )

}

export default CreateMeet