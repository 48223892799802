import React, { useContext, useState } from 'react'
import moment from 'moment';
import { API } from '../../../api/api';
import DataContext from '../../../context/DataContext'
import AuthenticatedContext from '../../../context/AuthenticatedContext';

import Food from '../../Cards/Food/Food'

function ReservCoffe() {

    const { isUser } = useContext(AuthenticatedContext);
    const { productsCoffee, reserved } = useContext(DataContext);

    const [ isLoadingReservation, setIsLoadingReservation ] = useState(false);
    const [ isReservado, setIsReservado ] = useState(false);

    const lunch = productsCoffee.filter((food) => food.category === '1');

    const handleReserved = async () => {

        setIsLoadingReservation(true);

        const horaToday = moment().format('HH:mm:ss');
        const dateToday = moment().format('YYYY-MM-DD')

        const formData = new FormData();
        formData.append('action', 'reserved')
        formData.append('today', dateToday)
        formData.append('productId', reserved.productId)
        formData.append('productAmount', reserved.productAmount)
        formData.append('productUser', isUser.id)
        formData.append('productTime', horaToday)
        formData.append('productDate', dateToday)

        try {
            
            await fetch(`${API.api}/coffee/`, {
                method: 'POST',
                body: formData
            })
            .then((response) => response.json())
            .then((data) => {
                if (data.ok) {
                    console.log(data.message);
                } else {
                    console.log(data.message);
                }
            })
            .catch((error) => {
                console.log(error.message);
            })
            .finally(() => {
                setIsReservado(true);
                setIsLoadingReservation(false);
            })

        } catch (error) {
            console.log(error.message);
        }

    }

    return (

        <>
        
            <h3 style={{marginBottom: '1rem'}}>Reservar Menú</h3>

            <hr style={{marginBottom: '1rem'}}/>

            <div className='--list-dresse'>

                {lunch.length > 0 ? (

                    <>
                        {lunch.map((lunch, i) => (
                            <Food key={i} type={'1'} product={lunch} />
                        ))}

                        {isReservado || lunch.isReserved !== 0 ? (
                            <button className='--bt' style={{width: '100%', height: '50px', borderRadius: '100px', color: '#FFFFFF', backgroundColor: 'var(--primary)'}}>Reservado</button>
                        ) : (
                            <button className='--bt' onClick={handleReserved} style={{width: '100%', height: '50px', borderRadius: '100px', color: '#FFFFFF', backgroundColor: 'var(--primary)'}}>{isLoadingReservation ? 'Reservando...' : 'Reservar'}</button>
                        )}

                    </>
                
                ) : (

                    <h4>Menú aun no disponible</h4>

                )}

            </div>

        </>

    )

}

export default ReservCoffe