import React, { useContext } from 'react'

import UIContext from '../../context/UIContext';

import HomeView from '../../components/views/Caffetin/HomeView';
import Bookings from '../../components/views/Caffetin/Bookings';
import ProfileView from '../../components/views/Caffetin/ProfileView';
import CreateView from '../../components/views/Caffetin/CreateView';

function Main() {

    const { isView } = useContext(UIContext);

    return (

        <main className='--main'>

            {isView === 'home' && ( <HomeView/> )}

            {isView === 'createProduct' && ( <CreateView/> )}

            {isView === 'bookings' && ( <Bookings/> )}
            {isView === 'profile' && ( <ProfileView/> )}

        </main>

    )

}

export default Main