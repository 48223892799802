import React, { useContext } from 'react'
import HomeView from '../../components/views/Student/HomeView'
import UIContext from '../../context/UIContext'
import CoursesView from '../../components/views/Student/CoursesView';
import SchedulesView from '../../components/views/Student/SchedulesView';
import PensionsView from '../../components/views/Student/PensionsView';
import ProfileView from '../../components/views/Student/ProfileView';
import CodeView from '../../components/views/Student/CodeView';
import ReservCoffe from '../../components/views/Student/ReservCoffe';

function Main() {

    const { isView } = useContext(UIContext);

    return (

        <main className='--main'>

            {isView === 'home' && ( <HomeView/> )}

            {isView === 'courses' && ( <CoursesView/> )}

            {isView === 'schedule' && ( <SchedulesView/> )}

            {isView === 'pensions' && ( <PensionsView/> )}

            {isView === 'profile' && ( <ProfileView/> )}

            {isView === 'code' && ( <CodeView/> )}

            {isView === 'viewMoreCoffe' && ( <ReservCoffe/> )}

        </main>

    )

}

export default Main