import React from 'react'
import { IconEdit, IconTrash } from '@tabler/icons-react'

import fotoDefault from '../../../static/img/FotoDefault.png'

import './styles.css'

function Product({ product }) {

    return (

        <div className='--card-product'>

            <div className='--content-product'>

                <div className='--image-product'>
                    <div className='--prev-product'>
                        <img src={fotoDefault} alt='Nombre del producto' />
                    </div>
                    <div className='--text-product'>
                        <h4>{product.name}</h4>
                        <p>s/ {product.price} - {product.amount} uni</p>
                    </div>
                </div>
                <div className='--opc-product'>
                    <button className='--btn-delete'><IconTrash/></button>
                    <button className='--btn-edit'><IconEdit/></button>
                </div>

            </div>

        </div>

    )

}

export default Product