import React, { useContext, useEffect } from 'react'

import AuthenticatedContext from "../../../context/AuthenticatedContext";
import DataContext from '../../../context/DataContext'

import './styles.css'
import Schedule from '../../Cards/Schedules/Schedule';

function SchedulesView() {

    const { isUser } = useContext(AuthenticatedContext)
    const { schedules, handleGetSchedules } = useContext(DataContext);

    useEffect(() => {
        if (schedules.length === 0) {
            handleGetSchedules(isUser.grade_code);
        }
    }, [schedules, handleGetSchedules, isUser]);
    
    return (
    
        <div>
            <h2>Horario</h2>
            <p>Aquí verás tu horario para puedas estar siempre al día.</p>
            <div className='--line'></div>
            {schedules.map((schedule, i) => (
                <Schedule key={i} schedule={schedule} />
            ))}
        </div>
    
    )

}

export default SchedulesView