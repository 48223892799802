import React, { useContext, useEffect } from 'react'

import DataContext from "../../context/DataContext";
import UIContext from '../../context/UIContext';

import Food from '../Cards/Food/Food'

function Foods() {

    const { handleChangeView } = useContext(UIContext);
    const { handleGetProducts, productsCoffee } = useContext(DataContext)

    useEffect(() => {
        if (productsCoffee.length === 0) {
            handleGetProducts();
        }
    }, [handleGetProducts, productsCoffee])

    return (

        <div>

            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <h3 className='--title-cards'>Cafeteria</h3>
                <button style={{ color: 'var(--primary)', fontWeight: 'bold', background: 'none' }} onClick={() => handleChangeView('viewMoreCoffe')}>Reservar</button>
            </div>

            <div className='--lst-cards'>
                {productsCoffee.map((product) => (
                    <Food key={product.id_product} type={'0'} product={product} />
                ))}
            </div>

        </div>

    )

}

export default Foods