import React, { useContext, useState, useEffect } from 'react'
import moment from 'moment';
import { IconChevronRight } from '@tabler/icons-react'

import AuthenticatedContext from '../../../context/AuthenticatedContext';

import Pension from '../../Cards/Pensions/Pension'

import './styles/pensions.css'

function PensionsView() {

    const { isUser } = useContext(AuthenticatedContext);

    const [ nextMonths, setNextMonths ] = useState([]);

    const fechaMoment = moment(isUser.tuition);
    const mesAbreviado = fechaMoment.format('MMM');
    const dia = fechaMoment.format('DD')

    useEffect(() => {
        if (isUser.tuition && isUser.rol === '3') {
            const nextMonthsArray = [];
            for (let i = 1; i <= 9; i++) {
                const nextMonth = fechaMoment.clone().add(i, 'months');
                nextMonthsArray.push({
                    mes: nextMonth.format('MMM'),
                    dia: nextMonth.format('DD'),
                    name: `Cuota ${i}`
                });
            }
            setNextMonths(nextMonthsArray);
        }
    }, [isUser.tuition, fechaMoment, isUser.rol]);

    return (

        <>
        
            <div className='--content-height'>

                <div className='--box-content'>
                    <span>Cuota 1</span>
                    <h1>s/. 250.00</h1>
                    <span>Vence 30/04/2024</span>
                </div>

            </div>

            <div className='--banks-confence'>
                <span>Bancos preferidos y datos importantes</span>
                <IconChevronRight/>
            </div>

            <div className='--totally'>
                <span>Total del periodo</span>
                <span><b>s/. 2,500.00</b></span>
            </div>

            <div className='--lst-coutas'>
                <div className={`--card-pension`}>
                    <div className='--col --col-A'>
                        <div className='--date'>
                            <span className='--span-day-mont'>{mesAbreviado}</span>
                            <span className='--span-day-numb'>{dia}</span>
                        </div>
                        <div className='--text'>
                            <h4>Matrícula</h4>
                            <span>Pagada</span>
                        </div>
                    </div>
                    <div className='--col --col-B'>
                        <span className='--price-totally'>s/. 250.00</span>
                    </div>
                </div>
                {nextMonths.map((next, i) => (
                    <Pension key={i} pension={next}/>
                ))}
            </div>

        </>

    )

}

export default PensionsView