import React from 'react'
import './styles/loader.css'

function Loader() {

    return (

        <span className='--loader'></span>

    )

}

export default Loader