import React, { useContext } from 'react'
import { IconHome, IconUserStar, IconBook, IconCalendar, IconUserCircle } from '@tabler/icons-react'

import UIContext from '../../context/UIContext'

import '../styles/footer.css'

function Footer() {

    const { isView, handleChangeView } = useContext(UIContext);

    return (

        <footer className={`--footer`}>

            <div className='--content-footer'>

                <button className={`--btn --btn-nav ${isView === 'home' ? '--btn-nav--active' : ''}`} onClick={() => handleChangeView('home')}><IconHome className='--ico-tabler'/> <span className='--txt'>Inicio</span></button>
                <button className={`--btn --btn-nav ${isView === 'students' ? '--btn-nav--active' : ''}`}  onClick={() => handleChangeView('students')}><IconUserStar className='--ico-tabler'/><span className='--txt'>Alumnos</span></button>
                <button className={`--btn --btn-nav ${isView === 'teachers' ? '--btn-nav--active' : ''}`}  onClick={() => handleChangeView('teachers')}><IconBook className='--ico-tabler'/><span className='--txt'>Test</span></button>
                <button className={`--btn --btn-nav ${isView === 'inventary' ? '--btn-nav--active' : ''}`}  onClick={() => handleChangeView('inventary')}><IconCalendar className='--ico-tabler'/><span className='--txt'>Horario</span></button>
                <button className={`--btn --btn-nav ${isView === 'profile' ? '--btn-nav--active' : ''}`}  onClick={() => handleChangeView('profile')}><IconUserCircle className='--ico-tabler'/><span className='--txt'>Perfil</span></button>

            </div>

        </footer>

    )

}

export default Footer