import React, { createContext, useContext, useEffect, useState, useCallback } from "react";
import Cookies from "js-cookie";
import { API } from '../api/api'
import { jwtDecode } from "jwt-decode";
import UIContext from "./UIContext";

const AuthenticatedContext = createContext();

export const AuthenticatedProvider = ({ children }) => {

    const { handleSnackBar, handleViewMenu } = useContext(UIContext);

    const auth = Cookies.get('c_user');

    const [ isAuthenticated, setIsAuthenticated ] = useState(auth && auth.trim() !== '');
    const [ isLoadingAuth, setIsLoadingAuth ] = useState(false);
    const [ isUser, setIsUser ] = useState([]);
    const [ isMessagesAuth, setIsMessagesAuth ] = useState({
        type: '',
        message: ''
    }); 

    const handleUser = useCallback(async (code) => {

        try {
            
            const cachedUser = Cookies.get('c_client');

            if (cachedUser) {
                
                const userData = jwtDecode(cachedUser);
                setIsUser(userData);

            } else {

                const formData = new FormData();
                formData.append('code', code);

                await fetch(`${API.api}/usuarios/`, {
                    method: 'POST',
                    body: formData
                })
                .then((response) => response.json())
                .then((data) => {
                    if (data.ok) {
                        const userData = jwtDecode(data.token);
                        Cookies.set('c_client', data.token, { expires: 365, secure: true });
                        setIsUser(userData);
                    } else {
                        setIsMessagesAuth({
                            type: 'warning',
                            message: data.message
                        });
                        handleSnackBar(isMessagesAuth);
                    }
                })
                .catch((error) => {
                    setIsMessagesAuth({
                        type: 'error',
                        message: error.message
                    });
                    handleSnackBar(isMessagesAuth);
                })

            }

        } catch (error) {
            setIsMessagesAuth({
                type: 'error',
                message: error.message
            });
            handleSnackBar(isMessagesAuth);

        }

    }, [handleSnackBar, setIsMessagesAuth, isMessagesAuth]);

    const handleLogin = async (uuid, uupw) => {

        if (uuid === '' && uupw === '') {
            setIsMessagesAuth({
                type: 'warning',
                message: 'Por favor los campos no deben estar vacios.'
            });
            handleSnackBar(isMessagesAuth);
            return;
        }

        setIsLoadingAuth(true);
        
        const formData = new FormData();
        formData.append('uuid', uuid)
        formData.append('uupw', uupw)

        try {
            
            await fetch(`${API.api}/usuarios/login/`, {
                method: 'POST',
                body: formData
            })
            .then((response) => response.json())
            .then((data) => {
                if (data.ok) {
                    
                    setIsMessagesAuth({
                        type: 'success',
                        message: data.message
                    });
                    handleSnackBar(isMessagesAuth);
                    setIsAuthenticated(true);
                    handleUser(data.token)
                    Cookies.set('c_user', data.token, { expires: 365, secure: true });

                } else {

                    setIsMessagesAuth({
                        type: 'success',
                        message: data.message
                    });
                    handleSnackBar(isMessagesAuth);
                }
            })
            .catch((error) => {

                setIsMessagesAuth({
                    type: 'success',
                    message: error.message
                });
                handleSnackBar(isMessagesAuth);

            })
            .finally(() => {
                setIsLoadingAuth(false);
            })

        } catch (error) {

            setIsMessagesAuth({
                type: 'success',
                message: error.message
            });
            handleSnackBar(isMessagesAuth);

        }

    }

    const handleLogout = () => {

        setIsMessagesAuth({
            type: 'success',
            message: 'Se cerro la sesión con éxito.'
        });

        Cookies.remove('c_user');
        Cookies.remove('c_client');
        localStorage.removeItem('c_schedule');
        localStorage.removeItem('c_courses');
        setIsAuthenticated(false);
        handleViewMenu();

    }

    useEffect(() => {

        const getUser = async () => {
            if (Cookies.get('c_user') && isUser.length === 0) {
                await handleUser(Cookies.get('c_user'));
            }
        }
        getUser();
    
    }, [handleUser, isUser]);

    const contextValue = {
        isAuthenticated,
        isLoadingAuth,
        handleLogin,
        isUser,
        handleLogout
    }

    return (
        <AuthenticatedContext.Provider value={contextValue}>{children}</AuthenticatedContext.Provider>
    );

}

export default AuthenticatedContext;