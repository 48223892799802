import React, { useContext } from 'react'
import { IconMinus, IconPlus } from '@tabler/icons-react'

import DataContext from '../../../context/DataContext'
import fotoDefault from '../../../static/img/FotoDefault.png'

import './food.css'

function Product({ type, product }) {

    const { reserved, setReserved } = useContext(DataContext);

    const handleChangeAmount = (type) => {
        setReserved(prevR => ({ ...prevR, productId: product.id_product }));
        if (type === 'add' && reserved.productAmount < product.amount) {
            setReserved(prevReserved => ({
                ...prevReserved,
                productAmount: prevReserved.productAmount + 1
            }));
        } else if (type === 'min' && reserved.productAmount > 1) {
            setReserved(prevReserved => ({
                ...prevReserved,
                productAmount: prevReserved.productAmount - 1
            }));
        }
    };

    const handleChoosedProduct = (idProduct) => {
        setReserved(prevState => ({
            ...prevState,
            productId: idProduct
        }))
    }

    return (
        
        <>

            {type === '0' && (

                <div className='--card-product'>

                    <div className='--content-product'>

                        <div className='--image-product'>
                            <div className='--prev-product'>
                                <img src={fotoDefault} alt='Nombre del producto' />
                            </div>
                            <div className='--text-product'>
                                <h4>{product.name}</h4>
                                <p>s/ {product.price} - {product.amount} uni</p>
                            </div>
                        </div>

                    </div>

                </div>
            
            )}

            {type === '1' && (
                <div className={`--card-product-reserv ${product.isReserved !== 0 ? '--card-product-reserv--ok' : ''} ${product.id_product === reserved.productId ? '--card-product-reserv--active' : '--card-product-reserv' }`} onClick={() => handleChoosedProduct(product.id_product)}>

                    <div className='--content-product-reserv'>

                        <div className='--col'>

                            <div className='--figure'>
                                <img src={fotoDefault} alt='Foto por defecto de la cafeteria de Colegios y academia Sigma - Jauja' />
                            </div>

                            <div className='--data'>
                                <h4>{product.name}</h4>
                                <span>s./ {product.price}</span>
                            </div>

                        </div>

                        <div className='--col'>
                            {product.isReserved === 0 ? (
                            <div className='--sizes'>
                                <button className='--btn-min' onClick={() => handleChangeAmount('min')}><IconMinus/></button>
                                <div className='--btn-dress'>{reserved.productAmount}</div>
                                <button className='--btn-add' onClick={() => handleChangeAmount('add')}><IconPlus/></button>
                            </div>
                            ) : (
                                <div style={{backgroundColor: 'var(--primary)', color: '#FFFFFF', padding: '.5rem', borderRadius: '5px'}}>Reservado</div>
                            )}
                        </div>

                    </div>

                </div>
            )}

            {type === '2' && (
                <div className='--card-product'>

                    <div className='--content-product'>

                        <div className='--image-product'>
                            <div className='--prev-product'>
                                <img src={fotoDefault} alt='Nombre del producto' />
                            </div>
                            <div className='--text-product'>
                                <h4>{product.name}</h4>
                                <p>ID: {product.usuario}</p>
                                <p>Precio: s/ {product.price}</p>
                                <p>Cantidad: {product.amount} uni</p>
                                <p>Total: s/ {(product.price * product.amount).toFixed(2)}</p>
                            </div>
                        </div>

                    </div>

                </div>
            )}
        
        </>

    )

}

export default Product