import React from 'react'

import defaultFoto from '../../../static/img/FotoDefault.svg'

import './meet.css'

function MeetEmpty() {

    return (

        <div className={`--card-meet-empty`}>

            <div className='--card-meet-head'>
                <div className='--figure'>
                    <img src={defaultFoto} alt='Foto de perfil' />
                </div>
                <h4>No tenemos reuniones</h4>
            </div>

        </div>

    )

}

export default MeetEmpty