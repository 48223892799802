import React from 'react'

import './psychology.css'

function PsychologyCard({ mentalBox }) {

    return (

        <div className='--card-psychology'>
            <div className='--title-tip'>
                <h4>{mentalBox.title}</h4>
                <p>{mentalBox.content}</p>
            </div>
        </div>

    )

}

export default PsychologyCard