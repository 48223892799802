import React, { useContext } from 'react'
import { IconChevronLeft } from '@tabler/icons-react'

import UIContext from '../../../context/UIContext'
import AuthenticatedContext from '../../../context/AuthenticatedContext';

import { QRCode } from 'react-qrcode-logo';

import './styles/code.css'

function CodeView() {

    const { handleChangeView } = useContext(UIContext);
    const { isUser } = useContext(AuthenticatedContext);

    return (
    
        <>
        
            <div className='--head-back'>
                <button className='--btn-back' onClick={() => handleChangeView('profile')}><IconChevronLeft/></button>
                <div>Identificación</div>
            </div>

            <div className='--content-qr'>

                <div className='--box-qr'>
                    <QRCode value={isUser.id} style={{width: `200px`, height: `200px`}} fgColor='#006A42' qrStyle='dots' eyeRadius={10} classname='qr-reader' />
                
                    <div className='--nme-qr'>
                        <h4>{isUser.name} {isUser.lastname} {isUser.secondname}</h4>
                        <p>ID: {isUser.code}</p>
                    </div>
                </div>


            </div>

        </>
    
    )

}

export default CodeView