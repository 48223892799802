import React from 'react'
import { IconChevronRight } from '@tabler/icons-react'

import bannerCourseDefault from '../../../static/img/banner_course_default.png'

import './course.css'

function Course({ course }) {

    const bannerCourse = course.image_course === '' ? bannerCourseDefault : course.image_course;

    return (

        <div className='--card-course'>

            <div className='--card-course-image' style={{backgroundImage: `url(${bannerCourse})`}}></div>
            <div className='--card-course-text'>
                <div>
                    <h3>{course.name_course}</h3>
                    <p>{course.teacher_course}</p>
                </div>
                <div>
                    <button className='--btn-next'><IconChevronRight/></button>
                </div>
            </div>

        </div>

    )

}

export default Course