import React from 'react'

import Header from "../layout/Admin/Header";
import Footer from '../layout/Admin/Footer';
import Main from '../layout/Admin/Main';

function AdminView() {

    return (

        <>
        
            <Header/>

            <Main/>

            <Footer/>

        </>

    )
    
}

export default AdminView