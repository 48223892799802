import React, { useEffect } from 'react'
import moment from 'moment'

import defaultImage from '../../../static/img/FotoDefault.png'

import './schedule.css'

function Schedule({ schedule }) {

    const dayMap = {
        'LUN': 'lunes',
        'MAR': 'martes',
        'MIE': 'miercoles',
        'JUE': 'jueves',
        'VIE': 'viernes'
    };

    const today = moment();
    const currentDay = dayMap[schedule.day];

    const isActiveSchedule = currentDay === today.format('dddd') ? true : false

    useEffect(() => {
        if (isActiveSchedule) {
            schedule.classmates.forEach(classmate => {
                const startTime = moment(classmate.hour_start, 'HH:mm:ss');
                const notificationTime = startTime.subtract(5, 'minutes'); // Restar 5 minutos al inicio de la clase
    
                if (today.isBetween(notificationTime, startTime) || today.isSame(notificationTime)) {
                    // Verificar si las notificaciones push son compatibles en el navegador
                    if ('Notification' in window) {
                        // Solicitar permiso para mostrar notificaciones push
                        Notification.requestPermission().then(permission => {
                            if (permission === 'granted') {
                                // Crear y mostrar la notificación push
                                new Notification('¡Próxima clase!', {
                                    body: `La próxima clase (${classmate.course}) comienza en 5 minutos.`,
                                    icon: `${defaultImage}` // Reemplaza con la ruta de tu icono de notificación
                                });
                            }
                        });
                    }
                }
            });
        }
    }, [isActiveSchedule, schedule, today]);    

    return (
    
        <div className='--card-schedule-day'>

            <div className='--col-schedule-day'>
                <div className={`--day ${isActiveSchedule ? '--day--active' : ''}`}>
                    <span>{schedule.day}</span>
                </div>
            </div>

            <div className='--col-schedule-courses'>
                {schedule.classmates.map((classmate, i) => {
                    // Convertimos la hora de inicio y fin del compañero de clase a objetos moment
                    const startTime = moment(classmate.hour_start, 'HH:mm:ss');
                    const endTime = moment(classmate.hour_finish, 'HH:mm:ss');
                    // Verificamos si la hora actual está dentro del rango de horas
                    const isActive = today.isBetween(startTime, endTime) || today.isSame(startTime) || today.isSame(endTime);

                    return (
                        <div className={`--schedule-item ${isActiveSchedule && isActive ? '--schedule-item--active' : ''}`} key={i}>
                            <span>{startTime.format('hh:mm A')} - {endTime.format('hh:mm A')}</span>
                            <h3>{classmate.course}</h3>
                            <span>{classmate.teacher}</span>
                        </div>
                    );
                })}
            </div>

        </div>
    
    )

}

export default Schedule