import React, { useContext, useEffect, useState } from 'react'
import './styles/snackbar.css'
import UIContext from '../../context/UIContext';

function SnackBar({ snack }) {

    const { handleSnackBar } = useContext(UIContext);
    const [ isVisible, setIsVisible ] = useState(snack.message === '' ? false : true);
    const [ snackFake, setSnakeFake ] = useState({
        type: '',
        message: ''
    });

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(false);
            setSnakeFake({ type: '', message: ''})
            handleSnackBar(snackFake);
        }, 8000);

        return () => clearTimeout(timer);
    }, [handleSnackBar, snackFake]);

    return (

        <div className={`--absolute  --snackbar-position${isVisible ? '--active' : ''} --snackbar --snackbar-${snack.type}`}>{snack.message}</div>

    )

}

export default SnackBar