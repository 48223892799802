import React, { useContext, useEffect } from 'react'

import AuthenticatedContext from "../../../context/AuthenticatedContext";
import DataContext from '../../../context/DataContext'

import Course from '../../Cards/Courses/Course'

import './styles.css'

function CoursesView() {

    const { isUser } = useContext(AuthenticatedContext)
    const { courses, handleGetCourses } = useContext(DataContext);

    useEffect(() => {
        if (courses.length === 0) {
            handleGetCourses(isUser.grade_code);
        }
    }, [courses, isUser, handleGetCourses]);

    return (

        <div>
            <h2>Cursos</h2>
            <p>Aquí se listaran los cursos de tu grado.</p>
            <div className='--line'></div>
            {courses.map((course) => (
                <Course key={course.id_course} course={course} />
            ))}
        </div>

    )

}

export default CoursesView