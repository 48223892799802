import React, { useContext } from 'react'
import { IconBell, IconMenu, IconX } from '@tabler/icons-react';

import UIContext from '../../context/UIContext';
import AuthenticatedContext from '../../context/AuthenticatedContext'

import '../styles/header.css'

function Header() {

    const { timeOfDay, handleViewMenu, isViewMenu } = useContext(UIContext);
    const { isUser } = useContext(AuthenticatedContext);

    return (

        <header className={`--header ${isViewMenu ? '--header-border-none' : ''}`}>

            <div className='--row --row-A'>

                <button className='--btn --btn-head' onClick={handleViewMenu}>{isViewMenu ? <IconX className='--ico-tabler'/> : <IconMenu className='--ico-tabler'/>}</button>
                <button className='--btn --btn-head'><IconBell className='--ico-tabler'/></button>

            </div>

            <div className='--row --row-B'>

                <p>{timeOfDay},</p>
                <h1>{isUser.name}</h1>
                
            </div>

        </header>

    )
    
}

export default Header