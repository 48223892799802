import React, { useContext } from 'react'
import UIContext from '../../context/UIContext'
import AuthenticatedContext from '../../context/AuthenticatedContext';

import './menubar.css'

function MenuBar() {

    const { isViewMenu } = useContext(UIContext);
    const { handleLogout } = useContext(AuthenticatedContext);

    return (

        <div className={`--menu-content ${isViewMenu ? '--menu-content--active' : ''}`}>

            <div className='--menu-content-A'></div>

            <div className='--menu-content-B'>
                <button className='--btn-logout' onClick={handleLogout}>Cerrar Sesión</button>
            </div>

        </div>

    )

}

export default MenuBar